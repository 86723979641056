import React from "react";
import { css } from "@emotion/react";
import { color } from "constants/index";
import Icon from "atoms/Icon";

const style = Object.freeze({
  container: css`
  `,
  title: css`
    font-size: 1.1rem;
    letter-spacing: 3px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;
    margin-top: 64px;

    span {
      padding: 16px;
      border-bottom: 2px solid black;
    }
  `,
  body: css`
    width: 872px;
    background: #f0f0f060;
    border-radius: 16px;
    padding: 32px;
    margin: auto;
    font-size: 0.8rem;
    letter-spacing: 1.2px;
    text-align: center;

    li {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 4px;
      margin-top: 4px;
    }
  `,
  alignLeft: css`
    text-align: left;
  `,
  icon: css`
    margin-right: 4px;
    margin-left: 4px;
  `,
  centering: css`
    display: flex;
    justify-content: center;
  `,
  qa: css``,
  qaBody: css`
    margin-top: 32px;
    padding: 64px 128px;

    li {
      margin-bottom: 28px;
      background: ${color.white};
      padding: 16px;
      border-radius: 8px;
      border: 2px solid #8888882e;
    }
  `,
  question: css`
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 1.2px;
    marign-bottom: 12px;

    &:before {
      content: "Q. ";
      font-weight: bold;
    }
  `,
  answer: css`
    color: ${color["gray-6"]};

    p:first-child:before {
      content: "A. ";
      font-weight: bold;
    }
  `,
  goBack: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    cursor: pointer;

    p {
      margin: 4px;
      text-align: center;
      font-weight: bold;
    }

    &:hover {
      opacity: 0.5;
    }

    .icon-up {
      position: relative;
    }

    &:hover .icon-up {
      animation: 0.3s linear 0s infinite alternate bounding;
    }

    @keyframes bounding {
      0% {
        top: 4px;
      }
      100% {
        top: 0;
      }
    }
  `,
  footer: css`
    min-height: 100px;
  `,
});

interface Props {
  goBack?: () => void;
}

const Help: React.FC<Props> = ({ goBack }) => {
  return (
    <div css={style.container}>
      <div css={style.goBack}>
        <div className="icon-up">
          <Icon icon="up" onClick={goBack} />
        </div>
        <p onClick={goBack}>戻る</p>
      </div>
      <section>
        <h2 css={style.title}>
          <span>Egg Cutter について</span>
        </h2>
        <div
          css={[
            style.body,
            css`
              font-size: 0.9rem;
              padding: 64px 0;
            `,
          ]}
        >
          <p>Egg Cutter は音声ファイルを分割することのできるWebツールです。</p>
          <p>音声を流しながらご自身の好きなタイミングで</p>
          <p>分割位置を決めてファイルを分割することができます。</p>
          <p>好きなファイルを選択して自由にファイルを分割してみてください。</p>
        </div>
      </section>
      <section>
        <h2 css={style.title}>
          <span>作業の流れ</span>
        </h2>
        <div css={[style.body]}>
          <ul
            css={css`
              padding: 32px;
            `}
          >
            <li>
              1. 左側のサイドバーより
              <strong
                css={css`
                  display: inline-block;
                  position: relative;
                  bottom: 2px;
                  padding: 6px;
                  padding-right: 12px;
                  padding-bottom: 6px;
                  margin: 4px;
                  font-size: 0.6rem;
                  background: ${color["gray-8"]};
                  color: ${color.white};
                  border-radius: 12px;
                  font-weight: bold;
                `}
              >
                <Icon
                  css={[
                    style.icon,
                    css`
                      position: relative;
                      top: 2px;
                    `,
                  ]}
                  icon="upload"
                  size="10px"
                  color={color.white}
                />
                アップロード
              </strong>
              ボタンを押して、ファイルを選択
            </li>
            <li css={style.centering}>
              <Icon icon="arrowdown" size="14px" />
            </li>
            <li>
              2. メイン画面のプレイヤー
              <strong
                css={css`
                  display: inline-block;
                  position: relative;
                  bottom: 2px;
                  padding: 4px;
                  padding-right: 12px;
                  padding-bottom: 6px;
                  margin: 4px;
                  font-size: 0.6rem;
                  border: 2px solid ${color["gray-8"]};
                  border-radius: 14px;
                  font-weight: bold;
                `}
              >
                <Icon
                  css={[
                    style.icon,
                    css`
                      position: relative;
                      top: 2px;
                    `,
                  ]}
                  icon="play"
                  size="10px"
                />
                再生ボタン
              </strong>
              を押してファイルを再生
            </li>
            <li css={style.centering}>
              <Icon icon="arrowdown" size="14px" />
            </li>
            <li>
              3. 再生位中にコントロールパネルの
              <strong
                css={css`
                  display: inline-block;
                  position: relative;
                  bottom: 2px;
                  padding: 6px;
                  padding-right: 12px;
                  margin: 4px;
                  font-size: 0.6rem;
                  background: ${color.primary};
                  border-radius: 12px;
                  font-weight: bold;
                `}
              >
                <Icon
                  css={[
                    style.icon,
                    css`
                      position: relative;
                      top: 2px;
                    `,
                  ]}
                  icon="pin"
                  size="10px"
                />
                ピンを立てる
              </strong>
              ボタンを押して分割位置を確定
            </li>
            <li css={style.centering}>
              <Icon icon="arrowdown" size="14px" />
            </li>
            <li>
              4. 位置を全て選び終わったら
              <strong
                css={css`
                  display: inline-block;
                  position: relative;
                  bottom: 2px;
                  padding: 6px;
                  padding-right: 14px;
                  padding-left: 8px;
                  margin: 4px;
                  font-size: 0.6rem;
                  background: ${color.primary};
                  border-radius: 14px;
                  font-weight: bold;
                `}
              >
                <Icon
                  css={[
                    style.icon,
                    css`
                      position: relative;
                      top: 2px;
                    `,
                  ]}
                  icon="cut"
                  size="10px"
                />
                カット
              </strong>
              ボタンを押して分割を開始
            </li>
            <li css={style.centering}>
              <Icon icon="arrowdown" size="14px" />
            </li>
            <li>
              5. 分割が終わったらダイアログを閉じて
              <strong
                css={css`
                  display: inline-block;
                  position: relative;
                  bottom: 2px;
                  padding: 6px;
                  padding-right: 12px;
                  margin: 4px;
                  font-size: 0.6rem;
                  background: ${color.primary};
                  border-radius: 14px;
                  font-weight: bold;
                `}
              >
                <Icon
                  css={[
                    style.icon,
                    css`
                      position: relative;
                      top: 2px;
                    `,
                  ]}
                  icon="download"
                  size="10px"
                />
                ダウンロード
              </strong>
              ボタンを押してZipファイルをダウンロード
            </li>
            <li css={[style.centering]}>
              <Icon icon="arrowdown" size="14px" />
            </li>
            <li>
              <span
                css={css`
                  font-size: 0.9rem;
                  padding: 12px 24px;
                  border-radius: 24px;
                  background: ${color["gray-8"]};
                  color: ${color.white};
                `}
              >
                6. 作業完了
              </span>
            </li>
          </ul>

          <div
            css={css`
              padding: 16px;
              background: ${color.white};
              margin: 16px 128px;
              border-radius: 8px;
              font-size: 0.6rem;
            `}
          >
            <p>作業後 1. で選択したファイルのうち、未作業のファイルがあれば</p>
            <p>
              サイドバーよりファイルを切り替えて
              同様の作業を行うことができます。
            </p>
          </div>
        </div>
      </section>
      <section css={style.qa}>
        <h2 css={style.title}>
          <span>Q & A</span>
        </h2>
        <div css={[style.body, style.qaBody, style.alignLeft]}>
          <ul>
            <li>
              <div css={style.question}>ファイルは何ファイルまで選択可能？</div>
              <div css={style.answer}>
                <p>同時に編集できるのは10ファイルまでです。</p>
              </div>
            </li>
            <li>
              <div css={style.question}>
                分割位置を間違って選択してしまった。
              </div>
              <div css={style.answer}>
                <p>
                  それぞれの分割位置の
                  <Icon
                    css={[
                      style.icon,
                      css`
                        position: relative;
                        top: 2px;
                      `,
                    ]}
                    icon="edit"
                    size="12px"
                    color={color["gray-4"]}
                  />
                  編集アイコンを押して編集可能です。
                </p>
              </div>
            </li>
            <li>
              <div css={style.question}>
                それぞれの分割ファイルの名前を変更したい。
              </div>
              <div css={style.answer}>
                <p>
                  それぞれの分割位置の
                  <Icon
                    css={[
                      style.icon,
                      css`
                        position: relative;
                        top: 2px;
                      `,
                    ]}
                    icon="edit"
                    size="12px"
                    color={color["gray-4"]}
                  />
                  編集アイコンを押した後に分割位置同様、編集可能です。
                </p>
              </div>
            </li>
            <li>
              <div css={style.question}>操作を取り消したい。</div>
              <div css={style.answer}>
                <p>
                  メイン今画面コントロールパネルから操作を 一つ前に戻す
                  ことができます。分割位置を全て
                  削除してしまったあとも同様の操作で
                  一つ前の操作に戻ることができます。
                </p>
              </div>
            </li>
            <li>
              <div css={style.question}>対応ファイルを知りたい。</div>
              <div css={style.answer}>
                <p>今のところmp3ファイルに対応しています。</p>
              </div>
            </li>
            <li>
              <div css={style.question}>
                エラーが発生して、うまく操作ができない。
              </div>
              <div css={style.answer}>
                <p>
                  お手数ですが、お問い合わせのリンクよりお問い合わせください。なるべく早く対応させて頂きます。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section css={style.footer}></section>
    </div>
  );
};

export default Help;
